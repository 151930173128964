import { usePrevious } from "@fluentui/react-hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { MAX_COMPRESSION_NUM_MAX } from "../../config/constant";
import { useQueryAllPages } from "../../hooks/useQuery";
import { ContentPickerI18n } from "../../locales";
import { requestWeb, WEB_API } from "../../services/request";
import { SortableData } from "../cards/SortableCard";
import DocPickerDialog from "./DocPickerDialog";

export default function CategoryCompressionDialog({
  hidden,
  onDismiss,
  categoryId,
}: {
  hidden?: boolean;
  categoryId?: string;
  onDismiss?: () => void;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const onSubmit = useCallback(
    (items: SortableData[]) => {
      setDataList(items);
      return requestWeb(WEB_API.myTenantCategoryZip, {
        method: "POST",
        vars: { categoryId: categoryId! },
        data: {
          documentIds: items.filter(item => item.entityType === "doc").map(item => item.id!),
          bundleIds: items.filter(item => item.entityType === "bundle").map(item => item.id!),
        },
      }).then(() => {
        queryClient.invalidateQueries(WEB_API.myTenantCategoryZipUrl);
        onDismiss && onDismiss();
      });
    },
    [categoryId, onDismiss, queryClient]
  );

  const prevCatgegoryId = usePrevious(categoryId);
  const { values: bundles = [], indicatorVisible: isLoadingBundles } = useQueryAllPages(
    !hidden && WEB_API.myTenantCategoryBundles,
    {
      vars: { categoryId: categoryId! },
      params: { $orderby: "publish_at desc" },
    }
  );
  const { values: docs = [], indicatorVisible: isLoadingDocs } = useQueryAllPages(
    !hidden && WEB_API.myTenantCategoryDocuments,
    {
      vars: { categoryId: categoryId! },
      params: { $orderby: "publish_at desc" },
    }
  );
  const items = useMemo(() => [...bundles, ...docs], [bundles, docs]);
  const [dataList, setDataList] = useState<SortableData[]>(items.slice(0, MAX_COMPRESSION_NUM_MAX));
  useEffect(() => {
    if (!hidden && categoryId !== prevCatgegoryId) {
      setDataList(items.slice(0, MAX_COMPRESSION_NUM_MAX)); // reset
    }
  }, [categoryId, hidden, prevCatgegoryId, items]);
  return (
    <>
      <DocPickerDialog
        hidden={hidden}
        title={t(ContentPickerI18n.compressTitle)}
        subTitle={t(ContentPickerI18n.pickerTips, { count: MAX_COMPRESSION_NUM_MAX })}
        submitText={t(ContentPickerI18n.submit)}
        enableShimmer={isLoadingBundles || isLoadingDocs}
        maxItems={MAX_COMPRESSION_NUM_MAX}
        items={items}
        selectedItems={dataList}
        showSelectAll={true}
        onSubmit={onSubmit}
        onDismiss={onDismiss}
      />
    </>
  );
}
