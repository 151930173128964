import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import SmallCard from "../../components/cards/SmallCard";
import { IconName } from "../../config/icons";
import { ContentHubNS, Mine } from "../../locales";

import CopyCallout from "../common/CopyCallout";
import MoreButton from "../common/MoreButton";
import { showDeleteDialog } from "../dialog/DeleteDialog";

import { Icon, makeStyles } from "@fluentui/react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { currentApp } from "../../config/app";
import { ResourceScope } from "../../config/rc";
import { TingDoc } from "../../models/TingDoc";
import { logger } from "../../services/logger";
import {
  EntityType,
  generateRCShareInfo,
  RCDocumentType,
  ShareInfo,
  SourcePageType,
} from "../../services/share";
import { getStorage } from "../../services/storage";
import ShimmerCard from "../cards/ShimmerCard";
import MetaEditorDialog from "../dialog/MetaEditorDialog";

const targetPrefix = "SamllCard_Button";
const ImportingFailed = 3;
const Importing = 2;

const useStyles = makeStyles({ deleteBtn: { pointerEvents: "auto" } });

export interface RCDocumentListProps {
  scope: ResourceScope;
  showCreator?: boolean;
  showTime?: boolean;
  docs?: CDS.Document[];
  delDoc?: (doc: CDS.Document) => any;
  tapDoc?: (doc: CDS.Document) => any;
  onAddToCategory?: (doc: CDS.Document) => any;
  onReadingDetailsDownload?: (docId: string) => void;
  onRemoveFromCategory?: (doc: CDS.Document) => any;
  patchMeta?: (meta: Partial<CDS.DocumentMeta>, doc: CDS.Document) => any;
  editDoc?: (doc: CDS.Document) => any;
  hideLink?: boolean;
  sourcePageType?: SourcePageType;
  isLoading?: boolean;
  hasNextPage?: boolean;
  loadMore?: () => void;
  allowLinkType?: RCDocumentType[];
}

export default function RCDocumentList({
  scope,
  docs,
  delDoc,
  tapDoc,
  patchMeta,
  editDoc,
  onAddToCategory,
  onReadingDetailsDownload,
  onRemoveFromCategory,
  hideLink,
  showCreator,
  showTime,
  sourcePageType,
  isLoading,
  hasNextPage,
  loadMore = () => {},
  allowLinkType,
}: RCDocumentListProps): React.ReactElement {
  const { t } = useTranslation();
  const [delDocList, setDelDocList] = useState<string[]>([]);
  const RemoveDoc = useCallback(
    (doc: CDS.Document) => {
      setDelDocList(array => [...array, doc.id!]);
      Promise.resolve(delDoc && delDoc(doc)).then(() => {
        setDelDocList(array => array.filter(item => item !== doc.id!));
      });
    },
    [delDoc]
  );

  const [copyItem, setCopyItem] = useState<ShareInfo | undefined>();
  const closeCopyCallout = useCallback(() => setCopyItem(undefined), []);

  const [editMeta, setEditMeta] = useState<Partial<CDS.DocumentMeta & { _id?: string }>>();
  const closeMetaDiaolog = useCallback(() => setEditMeta(undefined), []);
  const onMetaUpdate = useCallback(meta => {
    setEditMeta(old => ({ ...old, ...meta }));
    return true;
  }, []);
  const onMetaSubmit = useCallback(() => {
    const doc = docs?.find(d => d.id === editMeta?._id);
    logger?.telemetry("ClickVideoMetaUpdateSaveBtn", {
      DocId: doc?.id!,
    });

    if (doc && patchMeta) {
      const data: Partial<CDS.DocumentMeta> = {};
      const { cover, name } = editMeta as CDS.DocumentMeta;
      if (cover && cover !== doc.metadata?.cover) {
        data.cover = cover;
      }
      if (name && name !== doc.metadata?.name) {
        data.name = name;
      }
      if (Object.keys(data).length > 0) {
        patchMeta(data, doc);
      }
    }
    setEditMeta(undefined);
  }, [docs, editMeta, patchMeta]);
  const justReadId = getStorage("justReadId");
  const styles = useStyles();

  const [ref] = useInfiniteScroll({
    loading: !!isLoading,
    onLoadMore: loadMore,
    hasNextPage: !!hasNextPage,
  });

  const getMoreButtonItems = useCallback(
    (doc, hasCategory) => [
      onAddToCategory &&
        !hasCategory && {
          key: `addCategory`,
          name: t(Mine.actionAddCategory),
          iconProps: { iconName: IconName.Add },
          onClick: () => onAddToCategory(doc),
        },
      onRemoveFromCategory &&
        hasCategory && {
          key: `removeCategory`,
          name: t(Mine.actionRemoveCategory),
          iconProps: { iconName: IconName.PageRemove },
          onClick: () => onRemoveFromCategory(doc),
        },
      (patchMeta || editDoc) && {
        key: "edit",
        name: t(Mine.actionEdit),
        iconProps: { iconName: IconName.Edit },
        onClick: () => {
          logger?.telemetry("ClickDocEditBtn", {
            DocId: doc.id!,
            DocType: doc.metadata?.type!,
          });
          if (editDoc && TingDoc.isTingDoc(doc)) {
            editDoc(doc);
          } else if (patchMeta) {
            const { id, metadata } = doc;
            setEditMeta({ _id: id!, name: metadata?.name, cover: metadata?.cover });
          }
        },
      },
      !hideLink &&
        (!allowLinkType || allowLinkType.includes(doc.metadata?.type)) && {
          key: "link",
          name: t(Mine.actionDeeplink),
          iconProps: { iconName: IconName.Link },
          onClick: () => {
            logger?.telemetry("CopyDeepLink", {
              WorkType: EntityType.Document,
              DocumentType: doc.metadata?.type || RCDocumentType.TingDoc,
              WorkId: doc.id!,
              Name: scope === ResourceScope.feeds ? doc.metadata?.name! : undefined!,
              Scope: scope,
              Tags: doc.tags! as any,
              CategoryId: doc.extended?.categoryId!,
              Location: window.location.pathname,
              Target: "DocumentCard",
            });
            setCopyItem(
              generateRCShareInfo(
                scope,
                EntityType.Document,
                doc,
                currentApp.entityId,
                sourcePageType
              )
            );
          },
        },
      delDoc &&
        !hasCategory && {
          key: "delete",
          name: t(Mine.actionDelete),
          iconProps: { iconName: IconName.Delete },
          onClick: () => {
            showDeleteDialog({
              title: t(Mine.deleteTitle),
              subText: t(Mine.deleteContent),
              doDelete: () => RemoveDoc(doc),
            });
          },
        },
    ],
    [
      RemoveDoc,
      allowLinkType,
      delDoc,
      editDoc,
      hideLink,
      onAddToCategory,
      onRemoveFromCategory,
      patchMeta,
      scope,
      sourcePageType,
      t,
    ]
  );

  return (
    <>
      {docs &&
        docs.length !== 0 &&
        docs.map((doc, i) => {
          if (!doc) {
            return null;
          }
          const hasCategory = !!doc.extended?.categoryId;
          // const isPublic = isPublicDoc(doc.extended?.permission, doc.metadata?.status);
          const quickRemove = !!(
            (doc.metadata?.status === ImportingFailed || doc.metadata?.status === Importing) &&
            doc.id &&
            !delDocList.includes(doc.id)
          );

          const moreButtonItems = getMoreButtonItems(doc, hasCategory).filter(data => data);

          return (
            <SmallCard
              rootRef={hasNextPage && i + 1 === docs.length ? ref : undefined}
              isJustRead={justReadId === doc.id}
              key={doc.id}
              doc={doc}
              showCreator={showCreator}
              showTime={showTime}
              onTap={tapDoc}
              isDeleting={!!doc.id && delDocList.includes(doc.id)}
            >
              {quickRemove ? (
                <Icon
                  className={styles.deleteBtn}
                  iconName={IconName.ChromeClose}
                  onClick={e => {
                    doc.id && RemoveDoc(doc);
                    e.stopPropagation();
                  }}
                />
              ) : (
                moreButtonItems.length > 0 && (
                  <MoreButton
                    id={`${targetPrefix}_${doc?.id}`}
                    title={doc.metadata?.name}
                    items={getMoreButtonItems(doc, hasCategory)}
                  />
                )
              )}
            </SmallCard>
          );
        })}
      {isLoading && (
        <>
          <ShimmerCard type="doc" showCreator={showCreator} />
          <ShimmerCard type="doc" showCreator={showCreator} />
          <ShimmerCard type="doc" showCreator={showCreator} />
          <ShimmerCard type="doc" showCreator={showCreator} />
        </>
      )}
      {patchMeta && (
        <MetaEditorDialog
          title={t(ContentHubNS.editDialogTitle)}
          hidden={!editMeta?._id}
          cover={editMeta?.cover}
          name={editMeta?.name}
          cacheKey={editMeta?._id}
          onUpdate={onMetaUpdate}
          onSubmit={onMetaSubmit}
          onDismiss={closeMetaDiaolog}
        />
      )}
      <CopyCallout
        item={copyItem}
        target={copyItem && `#${targetPrefix}_${copyItem?.id}`}
        onDismiss={closeCopyCallout}
      />
    </>
  );
}
