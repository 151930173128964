import { mergeStyles, registerIcons } from "@fluentui/react";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import {
  AccountManagementIcon,
  AddIcon,
  ArchiveIcon,
  Blocked2Icon,
  CameraIcon,
  CancelIcon,
  ChartIcon,
  CheckMarkIcon,
  ChevronDownIcon,
  ChevronDownMedIcon,
  ChevronLeftMedIcon,
  ChevronRightMedIcon,
  ChevronUpIcon,
  ChromeCloseIcon,
  CirclePauseSolidIcon,
  ClearIcon,
  CompletedIcon,
  CompletedSolidIcon,
  ContactIcon,
  CopyIcon,
  DeleteIcon,
  DoubleChevronDownIcon,
  DoubleChevronUpIcon,
  DownIcon,
  DownloadDocumentIcon,
  DownloadIcon,
  EditIcon,
  Emoji2Icon,
  ErrorBadgeIcon,
  ErrorIcon,
  ExpandMenuIcon,
  FileTemplateIcon,
  FilterSolidIcon,
  GlobalNavButtonIcon,
  GlobeIcon,
  HomeGroupIcon,
  InfoIcon,
  LibraryIcon,
  LightbulbIcon,
  LinkIcon,
  LocaleLanguageIcon,
  LocationDotIcon,
  LockSolidIcon,
  MicrophoneIcon,
  MoreIcon,
  MoreVerticalIcon,
  PageRemoveIcon,
  PauseIcon,
  PlayerSettingsIcon,
  PreviewLinkIcon,
  QRCodeIcon,
  RingerIcon,
  RocketIcon,
  SecurityGroupIcon,
  SettingsIcon,
  ShareiOSIcon,
  StackedLineChartIcon,
  StopSolidIcon,
  SwitcherStartEndIcon,
  TagIcon,
  TriangleSolidRight12Icon,
  UpIcon,
  UploadIcon,
  VideoSolidIcon,
  ViewIcon,
  WarningIcon,
} from "@fluentui/react-icons-mdl2";
import {
  MSNVideosSolidIcon,
  OneDriveLogoIcon,
  PowerPointDocumentIcon,
} from "@fluentui/react-icons-mdl2-branded";
import { ReactComponent as AIRecorder } from "../img/icons/airecorder.svg";
import { ReactComponent as Backspace } from "../img/icons/backspace.svg";
import { ReactComponent as Collection } from "../img/icons/collection.svg";
import { ReactComponent as Headphone } from "../img/icons/headphone.svg";
import { ReactComponent as HumanRecorder } from "../img/icons/humanrecorder.svg";
import { ReactComponent as HumanRecorderFill } from "../img/icons/humanrecorderfill.svg";
import { ReactComponent as HumanRecorderPause } from "../img/icons/humanrecorderpause.svg";
import { ReactComponent as TingDoc } from "../img/icons/tingdoc.svg";
import { ReactComponent as Wechat } from "../img/icons/wechat.svg";

const iconStyle = mergeStyles({
  width: "1em",
  height: "1em",
  fill: "currentColor",
  verticalAlign: "middle",
});

const icons = {
  // react-icons-mdl2和react-icons-mdl2-branded自带的icon
  AccountManagement: <AccountManagementIcon className={iconStyle} />,
  Add: <AddIcon className={iconStyle} />,
  Archive: <ArchiveIcon className={iconStyle} />,
  Camera: <CameraIcon className={iconStyle} />,
  Cancel: <CancelIcon className={iconStyle} />,
  Chart: <ChartIcon className={iconStyle} />,
  CheckMark: <CheckMarkIcon className={iconStyle} />,
  ChevronDown: <ChevronDownIcon className={iconStyle} />,
  ChevronDownMed: <ChevronDownMedIcon className={iconStyle} />,
  ChevronLeft: <ChevronLeftMedIcon className={iconStyle} />,
  ChevronRight: <ChevronRightMedIcon className={iconStyle} />,
  ChevronUp: <ChevronUpIcon className={iconStyle} />,
  ChromeClose: <ChromeCloseIcon className={iconStyle} />,
  CirclePauseSolid: <CirclePauseSolidIcon className={iconStyle} />,
  CompletedSolid: <CompletedSolidIcon className={iconStyle} />,
  Copy: <CopyIcon className={iconStyle} />,
  Delete: <DeleteIcon className={iconStyle} />,
  Down: <DownIcon className={iconStyle} />,
  DownloadDocument: <DownloadDocumentIcon className={iconStyle} />,
  Download: <DownloadIcon className={iconStyle} />,
  Edit: <EditIcon className={iconStyle} />,
  Emoji2: <Emoji2Icon className={iconStyle} />,
  Error: <ErrorIcon className={iconStyle} />,
  ExpandMenu: <ExpandMenuIcon className={iconStyle} />,
  FileTemplate: <FileTemplateIcon className={iconStyle} />,
  FilterSolid: <FilterSolidIcon className={iconStyle} />,
  GlobalNavButton: <GlobalNavButtonIcon className={iconStyle} />,
  Globe: <GlobeIcon className={iconStyle} />,
  HomeGroup: <HomeGroupIcon className={iconStyle} />,
  Info: <InfoIcon className={iconStyle} />,
  Library: <LibraryIcon className={iconStyle} />,
  Lightbulb: <LightbulbIcon className={iconStyle} />,
  Link: <LinkIcon className={iconStyle} />,
  LocaleLanguage: <LocaleLanguageIcon className={iconStyle} />,
  LocationDot: <LocationDotIcon className={iconStyle} />,
  LockSolid: <LockSolidIcon className={iconStyle} />,
  Microphone: <MicrophoneIcon className={iconStyle} />,
  More: <MoreIcon className={iconStyle} />,
  MoreVertical: <MoreVerticalIcon className={iconStyle} />,
  PageRemove: <PageRemoveIcon className={iconStyle} />,
  Pause: <PauseIcon className={iconStyle} />,
  PlayerSettings: <PlayerSettingsIcon className={iconStyle} />,
  PreviewLink: <PreviewLinkIcon className={iconStyle} />,
  QRCode: <QRCodeIcon className={iconStyle} />,
  Ringer: <RingerIcon className={iconStyle} />,
  Rocket: <RocketIcon className={iconStyle} />,
  SecurityGroup: <SecurityGroupIcon className={iconStyle} />,
  Settings: <SettingsIcon className={iconStyle} />,
  ShareiOS: <ShareiOSIcon className={iconStyle} />,
  StackedLineChart: <StackedLineChartIcon className={iconStyle} />,
  StopSolid: <StopSolidIcon className={iconStyle} />,
  SwitcherStartEnd: <SwitcherStartEndIcon className={iconStyle} />,
  Tag: <TagIcon className={iconStyle} />,
  TriangleSolidRight12: <TriangleSolidRight12Icon className={iconStyle} />,
  Up: <UpIcon className={iconStyle} />,
  Upload: <UploadIcon className={iconStyle} />,
  VideoSolid: <VideoSolidIcon className={iconStyle} />,
  View: <ViewIcon className={iconStyle} />,
  MSNVideosSolid: <MSNVideosSolidIcon className={iconStyle} />,
  PowerPointDocument: <PowerPointDocumentIcon className={iconStyle} />,
  OneDrive: <OneDriveLogoIcon className={iconStyle} />,
  // 组件库内部使用的字体
  DoubleChevronUp: <DoubleChevronUpIcon className={iconStyle} />,
  DoubleChevronDown: <DoubleChevronDownIcon className={iconStyle} />,
  Clear: <ClearIcon className={iconStyle} />,
  Contact: <ContactIcon className={iconStyle} />,
  ErrorBadge: <ErrorBadgeIcon className={iconStyle} />,
  Blocked2: <Blocked2Icon className={iconStyle} />,
  Warning: <WarningIcon className={iconStyle} />,
  Completed: <CompletedIcon className={iconStyle} />,
  // 外部引入的svg图片
  Headphone: <Headphone className={iconStyle} />,
  Wechat: <Wechat className={iconStyle} />,
  TingDoc: <TingDoc className={iconStyle} />,
  AIRecorder: <AIRecorder className={iconStyle} />,
  HumanRecorder: <HumanRecorder className={iconStyle} />,
  HumanRecorderFill: <HumanRecorderFill className={iconStyle} />,
  HumanRecorderPause: <HumanRecorderPause className={iconStyle} />,
  Backspace: <Backspace className={iconStyle} />,
  Collection: <Collection className={iconStyle} />,
} as const;

export function initIcons() {
  registerIcons({ icons });
  initializeFileTypeIcons();
}

export type IconNameKey = keyof typeof icons;

export const IconName: {
  [key in IconNameKey]: key;
} = Object.fromEntries(Object.keys(icons).map(k => [k, k])) as any;
