import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { MAX_COMPRESSION_NUM_MAX } from "../../config/constant";
import { DocumentStatus } from "../../config/permission";
import { useQueryAllPages } from "../../hooks/useQuery";
import { ContentPickerI18n } from "../../locales";
import { requestWeb, WEB_API } from "../../services/request";
import { RCDocumentType } from "../../services/share";
import { SortableData } from "../cards/SortableCard";
import DocPickerDialog from "./DocPickerDialog";

export default function TemplateCompressionDialog({
  hidden,
  onDismiss,
}: {
  hidden?: boolean;
  onDismiss?: () => void;
}) {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState<SortableData[]>([]);
  const queryClient = useQueryClient();

  const onSubmit = useCallback(
    (items: SortableData[]) => {
      setDataList(items);
      return requestWeb(WEB_API.myTenantTemplateZip, {
        method: "POST",
        data: {
          documentIds: items.filter(item => item.entityType === "doc").map(item => item.id!),
        },
      }).then(() => {
        queryClient.invalidateQueries(WEB_API.myTenantTemplateZipUrl);
        onDismiss && onDismiss();
      });
    },
    [onDismiss, queryClient]
  );

  const { values: items, indicatorVisible } = useQueryAllPages(
    WEB_API.myTenantDocuments,
    {
      params: {
        $top: 100,
        docTypes: [
          RCDocumentType.PPTTemplate,
          RCDocumentType.WordTemplate,
          RCDocumentType.ExcelTemplate,
        ],
      },
    },
    useCallback(
      doc =>
        doc.metadata?.status === DocumentStatus.Private ||
        doc.metadata?.status === DocumentStatus.Public,
      []
    )
  );
  useEffect(() => {
    if (!hidden && items) {
      setDataList(items.slice(0, MAX_COMPRESSION_NUM_MAX)); // reset
    }
  }, [hidden, items]);

  return (
    <>
      <DocPickerDialog
        hidden={hidden}
        title={t(ContentPickerI18n.compressTitle)}
        subTitle={t(ContentPickerI18n.pickerTips, { count: MAX_COMPRESSION_NUM_MAX })}
        submitText={t(ContentPickerI18n.submit)}
        enableShimmer={indicatorVisible}
        maxItems={MAX_COMPRESSION_NUM_MAX}
        items={items}
        selectedItems={dataList}
        showSelectAll={true}
        onSubmit={onSubmit}
        onDismiss={onDismiss}
      />
    </>
  );
}
