/* eslint-disable @dragongate/must-contain-test-file */
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  Image,
  Link,
  makeStyles,
  PrimaryButton,
  Text,
} from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RETIRE_DOC_URL } from "../../config/constant";
import { PagePath } from "../../config/routes";
import { useRouteNavCallbck } from "../../hooks/rc/useRoute";
import aboutImg from "../../img/about_part3.png";
import { rem } from "../../lib/unit";
import { AppNoticePageI18n, BundlesEditorI18n } from "../../locales";

const useCurrentStyles = makeStyles({
  textTitle: { margin: `${rem(27)} 0 ${rem(19)}`, fontWeight: "bold" },
  textContent: { whiteSpace: "pre-wrap" },
  textFooter: { whiteSpace: "pre-wrap", paddingTop: rem(40) },
  img: { width: "100%", "& img": { width: "100%" } },
});

export default function RetirementDialog({
  onDismiss,
  isAdmin,
}: {
  onDismiss?: () => void;
  isAdmin?: boolean;
}): React.ReactElement {
  const [hidden, setHidden] = useState(false);
  const onDownloadClick = useRouteNavCallbck(PagePath.admin);
  const clickHidden = useCallback(() => {
    setHidden(true);
    if (onDismiss) onDismiss();
  }, [onDismiss]);
  const { t } = useTranslation();
  const currentStyles = useCurrentStyles();

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      dialogContentProps={{
        title: <Image src={aboutImg} className={currentStyles.img} />,
        titleProps: { style: { padding: 0 } },
        styles: {
          inner: {
            height: `${rem(250)}`,
          },
        },
        showCloseButton: true,
      }}
      modalProps={{ isBlocking: true }}
    >
      <Text variant="large" block className={currentStyles.textTitle}>
        {t(AppNoticePageI18n.title)}
      </Text>
      <Text className={currentStyles.textContent}>{t(AppNoticePageI18n.body)}</Text>
      <Text block className={currentStyles.textFooter}>
        <Link target="_blank" href={RETIRE_DOC_URL}>
          {t(AppNoticePageI18n.link)}
        </Link>
      </Text>
      <DialogFooter>
        {isAdmin && (
          <PrimaryButton text={t(AppNoticePageI18n.goToDownload)} onClick={onDownloadClick} />
        )}
        <DefaultButton text={t(BundlesEditorI18n.ok)} onClick={clickHidden} />
      </DialogFooter>
    </Dialog>
  );
}
