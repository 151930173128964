import { useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, DialogFooter, IDialogContentProps, PrimaryButton, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { ContentPickerI18n } from "../../locales";
import DocumentPicker from "../picker/DocumentPicker";
import { SortableData } from "../cards/SortableCard";
interface DocPickerDialogProps {
  title: string;
  subTitle: string;
  submitText: string;
  hidden?: boolean;
  enableShimmer?: boolean;
  selectedItems?: SortableData[];
  items?: SortableData[];
  maxItems?: number;
  onDismiss?: () => void;
  onSubmit?: (dataList: SortableData[]) => void;
  showSelectAll?: boolean;
}

export default function DocPickerDialog({
  onSubmit,
  selectedItems,
  items,
  enableShimmer,
  maxItems = 5,
  showSelectAll,
  ...props
}: DocPickerDialogProps) {
  const { t } = useTranslation();
  const [selectedDataList, setSelectedDataList] = useState<SortableData[]>(selectedItems || []);
  const onBtnClick = useCallback(() => {
    if (selectedDataList && onSubmit) {
      onSubmit(selectedDataList);
    }
  }, [onSubmit, selectedDataList]);
  const onSelect = useCallback((item: SortableData) => {
    setSelectedDataList(items =>
      items.some(i => i.id === item.id) ? items.filter(i => i.id !== item.id) : [...items, item]
    );
  }, []);
  const dialogProp = useMemo<IDialogContentProps>(
    () => ({
      title: props.title,
      subText: props.subTitle,
      showCloseButton: true,
    }),
    [props.title, props.subTitle]
  );
  useEffect(() => {
    selectedItems && setSelectedDataList(selectedItems);
  }, [selectedItems]);
  return (
    <Dialog
      hidden={props.hidden}
      // modalProps={{ isBlocking: true }}
      onDismiss={props.onDismiss}
      dialogContentProps={dialogProp}
    >
      <DocumentPicker
        maxItems={maxItems}
        emptyMessage={t(ContentPickerI18n.emptyTips)}
        dataList={items}
        selectedItems={selectedDataList}
        onSelect={onSelect}
        enableShimmer={enableShimmer}
        showSelectAll={showSelectAll}
      />
      <DialogFooter>
        <Text>{t(ContentPickerI18n.summary, { count: selectedDataList.length })}</Text>
        <PrimaryButton
          disabled={!selectedDataList.length}
          onClick={onBtnClick}
          text={props.submitText}
        />
      </DialogFooter>
    </Dialog>
  );
}
