import { Icon, IIconProps } from "@fluentui/react";
import { getFileTypeIconProps, IFileTypeIconOptions } from "@fluentui/react-file-type-icons";
import { useMemo } from "react";
import { isAppleMac } from "../../config/browser";
import { IconName } from "../../config/icons";

export interface FileTypeIconProp extends IFileTypeIconOptions, IIconProps {
  fileName?: string;
}

/**
 *
 * @param {FileTypeIconProp} props
 */
export default function FileTypeIcon({
  fileName,
  size,
  extension,
  type,
  imageFileType = isAppleMac ? "png" : "svg",
  ...iconProps
}: FileTypeIconProp) {
  if (!extension && fileName && !type) {
    extension = fileName.split(".").pop();
  }
  const iconName = useMemo(
    () =>
      extension === "tingdoc"
        ? IconName.TingDoc
        : getFileTypeIconProps({
            extension,
            size,
            type,
            imageFileType,
          }).iconName,
    [extension, imageFileType, size, type]
  );
  return <Icon iconName={iconName} {...iconProps} />;
}
