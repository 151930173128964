/**
 * 通用
 */
export enum Common {
  /**
   * 默认标题
   */
  title = "Common:title",
  confirm = "Common:confirm",
  cancel = "Common:cancel",
  back = "Common:back",
  share = "Common:share",
  homeTitle = "Common:homeTitle",
  myBundlesTitle = "Common:myBundlesTitle",
  close = "Common:close",
}

/**
 * 授权页
 */
export enum Mine {
  emptyTitle = "Mine:emptyTitle",
  emptyDivider = "Mine:emptyDivider",
  emptyTips = "Mine:emptyTips",
  create = "Mine:create",
  createBundle = "Mine:createBundle",
  createListeningDoc = "Mine:createListeningDoc",
  docsTitle = "Mine:docsTitle",
  bundlesTitle = "Mine:bundlesTitle",
  allBundles = "Mine:allBundles",
  moreActions = "Mine:moreActions",
  actionAddCategory = "Mine:actionAddCategory",
  actionRemoveCategory = "Mine:actionRemoveCategory",
  actionDelete = "Mine:actionDelete",
  actionEdit = "Mine:actionEdit",
  actionWechat = "Mine:actionWechat",
  actionQrcode = "Mine:actionQrcode",
  actionDeeplink = "Mine:actionDeeplink",
  deleteTitle = "Mine:deleteTitle",
  deleteContent = "Mine:deleteContent",
  courseCount = "Mine:courseCount",
  justRead = "Mine:justRead",
  expand = "Mine:expand",
  collapse = "Mine:collapse",
  contentTitle = "Mine:contentTitle",
  collection = "Mine:collection",
  content = "Mine:content",
}

/**
 * 播放页
 */
export enum View {
  share = "View:share",
  video = "View:video",
  close = "View:close",
  download = "View:download",
  downloadReport = "View:downloadReport",
  downloadReport_ing = "View:downloadReport_ing",
  edit = "View:edit",
  copyLink = "View:copyLink",
  playHint = "View:playHint",
  errContent = "View:errContent",
}

export enum Card {
  importing = "Card:importing",
  importingWithDots = "Card:importingWithDots",
  importingFailed = "Card:importingFailed",
  uploadingFailed = "Card:uploadingFailed",
  deleting = "Card:deleting",
}

export enum BundleStatus {
  pending = "BundleStatus:pending",
  fail = "BundleStatus:fail",
}

export enum CopyCalloutI18n {
  description = "CopyCalloutI18n:description",
  copyButton = "CopyCalloutI18n:copyButton",
  tip = "CopyCalloutI18n:tip",
}

export enum CopyMessageBarI18n {
  message = "CopyMessageBarI18n:message",
}

export enum PageName {
  homePage = "PageName:homePage",
  followerPage = "PageName:followerPage",
  allBundlesPage = "PageName:allBundlesPage",
}

export enum AboutPageI18n {
  title = "AboutPageI18n:title",
  description = "AboutPageI18n:description",
  welcomeDescription = "AboutPageI18n:welcomeDescription",
  icon1Description = "AboutPageI18n:icon1Description",
  icon2Description = "AboutPageI18n:icon2Description",
  contactHint = "AboutPageI18n:contactHint",
}

export enum AdminPageI18n {
  pageName = "AdminPageI18n:pageName",
  navGroupName = "AdminPageI18n:navGroupName",
  contentHubNav = "AdminPageI18n:contentHubNav",
  navContent = "AdminPageI18n:navContent",
  navBundles = "AdminPageI18n:navBundles",
  addCatalog = "AdminPageI18n:addCatalog",
  assignmentNav = "AdminPageI18n:assignmentNav",
  appDeploymentHelpNav = "AdminPageI18n:appDeploymentHelpNav",
  title = "AdminPageI18n:title",
  actionTip = "AdminPageI18n:actionTip",
}

export enum MePageI18n {
  navContent = "MePageI18n:navContent",
  pageName = "MePageI18n:pageName",
  tipTitle = "MePageI18n:tipTitle",
  tipContent = "MePageI18n:tipContent",
  tipButton = "MePageI18n:tipButton",
}

export enum EmptyContentI18n {
  emptyTitle = "EmptyContentI18n:emptyTitle",
  emptySubTitle = "EmptyContentI18n:emptySubTitle",
}

export enum CategoryActionI18n {
  addTreeNode = "CategoryActionI18n:addTreeNode",
  deleteTreeNode = "CategoryActionI18n:deleteTreeNode",
  editTreeNode = "CategoryActionI18n:editTreeNode",
  notify = "CategoryActionI18n:notify",
  download = "CategoryActionI18n:download",
  compressResource = "CategoryActionI18n:compressResource",
  compressingResource = "CategoryActionI18n:compressingResource",
  downloadResource = "CategoryActionI18n:downloadResource",
  compressResourceBubble = "CategoryActionI18n:compressResourceBubble",
}

export enum ContentHubNS {
  create = "ContentHubNS:create",
  uploadFile = "ContentHubNS:uploadFile",
  createFromOneDrive = "ContentHubNS:createFromOneDrive",
  createFromLocal = "ContentHubNS:createFromLocal",
  createVideoFromOneDrive = "ContentHubNS:createVideoFromOneDrive",
  createFromComputer = "ContentHubNS:createFromComputer",
  editDialogTitle = "ContentHubNS:editDialogTitle",
  removeFromCatalog = "ContentHubNS:removeFromCatalog",
  removeFromCatalogDesc = "ContentHubNS:removeFromCatalogDesc",
  uploadTemplate = "ContentHubNS:uploadTemplate",
  emptyCreate = "ContentHubNS:emptyCreate",
}

export enum FilePicker {
  pickerTips = "FilePicker:pickerTips",
  emptyTips = "FilePicker:emptyTips",
  type = "FilePicker:type",
  name = "FilePicker:name",
  modified = "FilePicker:modified",
  modifiedBy = "FilePicker:modifiedBy",
  size = "FilePicker:size",
  next = "FilePicker:next",
  editDialogTitle = "FilePicker:editDialogTitle",
  title = "FilePicker:title",
  titleErrorEmpty = "FilePicker:titleErrorEmpty",
  cover = "FilePicker:cover",
  coverUpload = "FilePicker:coverUpload",
  coverUploadError = "FilePicker:coverUploadError",
  coverSizeError = "FilePicker:coverSizeError",
  coverTypeError = "FilePicker:coverTypeError",
  coverTips = "FilePicker:coverTips",
  coverChange = "FilePicker:coverChange",
  back = "FilePicker:back",
  done = "FilePicker:done",
  titlePlaceHolder = "FilePicker:titlePlaceHolder",
}
export enum CategoryPicker {
  dialogTitle = "CategoryPicker:dialogTitle",
  pickerTips = "CategoryPicker:pickerTips",
  emptyTips = "CategoryPicker:emptyTips",
  done = "CategoryPicker:done",
}

export enum ContentPickerI18n {
  dialogTitle = "ContentPickerI18n:dialogTitle",
  pickerTips = "ContentPickerI18n:pickerTips",
  emptyTips = "ContentPickerI18n:emptyTips",
  summary = "ContentPickerI18n:summary",
  next = "ContentPickerI18n:next",
  compressTitle = "ContentPickerI18n:compressTitle",
  submit = "ContentPickerI18n:submit",
  selectAll = "ContentPickerI18n:selectAll",
}

export enum CategoryDialogI18n {
  dialogTitle = "CategoryDialogI18n:dialogTitle",
  nameLabel = "CategoryDialogI18n:nameLabel",
  namePlaceholder = "CategoryDialogI18n:namePlaceholder",
  scopeLabel = "CategoryDialogI18n:scopeLabel",
  peoplePickerLabel = "CategoryDialogI18n:peoplePickerLabel",
  visibleToAll = "CategoryDialogI18n:visibleToAll",
  visibleToScope = "CategoryDialogI18n:visibleToScope",
  visibleToAdmin = "CategoryDialogI18n:visibleToAdmin",
  confirmButton = "CategoryDialogI18n:confirmButton",
  deleteAlert = "CategoryDialogI18n:deleteAlert",
  nameAlert = "CategoryDialogI18n:nameAlert",
}

export enum AssignmentDialogI18n {
  dialogTitle = "AssignmentDialogI18n:dialogTitle",
  peoplePickerLabel = "AssignmentDialogI18n:peoplePickerLabel",
  confirmButton = "AssignmentDialogI18n:confirmButton",
  deleteAlert = "AssignmentDialogI18n:deleteAlert",
  errorTip = "AssignmentDialogI18n:errorTip",
}

export enum SwitchRecorderDialogI18n {
  dialogTitle = "SwitchRecorderDialogI18n:dialogTitle",
  alert = "SwitchRecorderDialogI18n:alert",
  close = "SwitchRecorderDialogI18n:close",
}

export enum RecorderI18n {
  aiRecorder = "RecorderI18n:aiRecorder",
  humanRecorder = "RecorderI18n:humanRecorder",
  tooltip = "RecorderI18n:tooltip",
}

export enum EditNotificationDialogI18n {
  dialogTitle = "EditNotificationDialogI18n:dialogTitle",
  visibilityScopeLabel = "EditNotificationDialogI18n:visibilityScopeLabel",
  contentsLabel = "EditNotificationDialogI18n:contentsLabel",
  descriptionLabel = "EditNotificationDialogI18n:descriptionLabel",
  descriptionPlaceholder = "EditNotificationDialogI18n:descriptionPlaceholder",
  back = "EditNotificationDialogI18n:back",
  send = "EditNotificationDialogI18n:send",
}

export enum VisibilityScopeTagI18n {
  andOthers = "VisibilityScopeTagI18n:andOthers",
  visibleToAll = "VisibilityScopeTagI18n:visibleToAll",
  visibleToAdmin = "VisibilityScopeTagI18n:visibleToAdmin",
}

export enum AssignmentCenterI18n {
  createButtonTitle = "AssignmentCenterI18n:createButtonTitle",
  columnHeadName = "AssignmentCenterI18n:columnHeadName",
  columnHeadRole = "AssignmentCenterI18n:columnHeadRole",
  columnHeadTitle = "AssignmentCenterI18n:columnHeadTitle",
  columnHeadLocation = "AssignmentCenterI18n:columnHeadLocation",
  roleName = "AssignmentCenterI18n:roleName",
}

export enum AdminConsent {
  consentBtn = "AdminConsent:consentBtn",
  tooltip = "AdminConsent:tooltip",
}

export enum AppDeployment {
  dialogHeadline = "AppDeployment:dialogHeadline",
  dialogDetails = "AppDeployment:dialogDetails",
  dialogCancelBtn = "AppDeployment:dialogCancelBtn",
  dialogConfirmBtn = "AppDeployment:dialogConfirmBtn",
  docBubbleContent = "AppDeployment:docBubbleContent",
  bubbleContent = "AppDeployment:bubbleContent",
  bubbleBtn = "AppDeployment:bubbleBtn",
  messageBarContent = "AppDeployment:messageBarContent",
  messageBarBtn = "AppDeployment:messageBarBtn",
}

export enum ConsentComplete {
  title = "ConsentComplete:title",
  description = "ConsentComplete:description",
  button = "ConsentComplete:button",
}

export enum RCPeoplePickerI18n {
  inputPlaceholder = "RCPeoplePickerI18n:inputPlaceholder",
  noResultsTip = "RCPeoplePickerI18n:noResultsTip",
  suggestionsHeader = "RCPeoplePickerI18n:suggestionsHeader",
}

export enum RCTreeI18n {
  newTag = "RCTreeI18n:newTag",
  emptyCatalog = "RCTreeI18n:emptyCatalog",
}

export enum SettingsI18n {
  button = "SettingsI18n:button",
  languageSettingTitle = "SettingsI18n:languageSettingTitle",
  languageOption = "SettingsI18n:languageOption",
  resourcelanguageOption = "SettingsI18n:resourcelanguageOption",
  feedback = "SettingsI18n:feedback",
}

export enum SortableCardI18n {
  moveUp = "SortableCardI18n:moveUp",
  moveDown = "SortableCardI18n:moveDown",
  remove = "SortableCardI18n:remove",
}

export enum InputTips {
  emptyError = "InputTips:emptyError",
  lenError = "InputTips:lenError",
}

export enum NotificationTutor {
  title = "NotificationTutor:title",
  detail = "NotificationTutor:detail",
  action = "NotificationTutor:action",
  btnTips = "NotificationTutor:btnTips",
}

export enum RCNavI18n {
  templateTitle = "RCNavI18n:templateTitle",
  templateSubTitle = "RCNavI18n:templateSubTitle",
}

export enum TemplateTagsI18n {
  all = "TemplateTagsI18n:all",
  cancel = "TemplateTagsI18n:cancel",
  complete = "TemplateTagsI18n:complete",
  title = "TemplateTagsI18n:title",
  clear = "TemplateTagsI18n:clear",
}

export enum ContinueEditDialog {
  title = "ContinueEditDialog:title",
  content = "ContinueEditDialog:content",
  yes = "ContinueEditDialog:yes",
  no = "ContinueEditDialog:no",
}

export enum EditorI18n {
  title = "EditorI18n:title",
  save = "EditorI18n:save",
  discard = "EditorI18n:discard",
  discardDialogTitle = "EditorI18n:discardDialogTitle",
  discardDialogContent = "EditorI18n:discardDialogContent",
  discardDialogConfirm = "EditorI18n:discardDialogConfirm",
  discardDialogCancel = "EditorI18n:discardDialogCancel",
  metaDialogTitle = "EditorI18n:metaDialogTitle",
  audioPlaceholder = "EditorI18n:audioPlaceholder",
  notePlaceholder = "EditorI18n:notePlaceholder",
  ttsProgress = "EditorI18n:ttsProgress",
  audioGenerationError = "EditorI18n:audioGenerationError",
  tooManyCharactersErrorMsg = "EditorI18n:tooManyCharactersErrorMsg",
  notFillInErrorMsg = "EditorI18n:notFillInErrorMsg",
}

export enum FileFilterI18n {
  title = "FileFilterI18n:title",
  label = "FileFilterI18n:label",
  presentation = "FileFilterI18n:presentation",
  sheet = "FileFilterI18n:sheet",
  document = "FileFilterI18n:document",
  video = "FileFilterI18n:video",
  pdf = "FileFilterI18n:pdf",
}

export enum TTSVoiceI18n {
  displayName = "TTSVoiceI18n:displayName",
  description = "TTSVoiceI18n:description",
  language = "TTSVoiceI18n:language",
}

export enum SelectVoiceDialogI18n {
  dialogTitle = "SelectVoiceDialogI18n:dialogTitle",
  languageDropDownLabel = "SelectVoiceDialogI18n:languageDropDownLabel",
  voiceStackLabel = "SelectVoiceDialogI18n:voiceStackLabel",
  applyBtn = "SelectVoiceDialogI18n:applyBtn",
}

export enum AdministratorGuide {
  voiceAITitle = "AdministratorGuide:voiceAITitle",
  voiceAITipText = "AdministratorGuide:voiceAITipText",
  voiceAIFooter = "AdministratorGuide:voiceAIFooter",
  confirmBtn = "AdministratorGuide:confirmBtn",
  templateTitle = "AdministratorGuide:templateTitle",
  templateCreationTip = "AdministratorGuide:templateCreationTip",
  officeUploadTitle = "AdministratorGuide:officeUploadTitle",
  officeUploadContent = "AdministratorGuide:officeUploadContent",
  officeUploadConfirm = "AdministratorGuide:officeUploadConfirm",
  officeUploadGuideTitle = "AdministratorGuide:officeUploadGuideTitle",
  officeUploadGuideContent = "AdministratorGuide:officeUploadGuideContent",
}

export enum TingDocEditorUserGuide {
  audioAIUserGuideText = "TingDocEditorUserGuide:audioAIUserGuideText",
  recordingModeChange = "TingDocEditorUserGuide:recordingModeChange",
}

export enum TingDocLoadingDialogI18n {
  processingStatus = "TingDocLoadingDialogI18n:processingStatus",
  processingTips = "TingDocLoadingDialogI18n:processingTips",
  errorTips = "TingDocLoadingDialogI18n:errorTips",
  tryAgain = "TingDocLoadingDialogI18n:tryAgain",
  title = "TingDocLoadingDialogI18n:title",
}

export enum RecorderButtonI18n {
  label = "RecorderButtonI18n:label",
  error = "RecorderButtonI18n:error",
  errorTitle = "RecorderButtonI18n:errorTitle",
  errorDetail = "RecorderButtonI18n:errorDetail",
  pause = "RecorderButtonI18n:pause",
  record = "RecorderButtonI18n:record",
  generate = "RecorderButtonI18n:generate",
}

export enum ManualRecordingBubbleI18n {
  title = "ManualRecordingBubbleI18n:title",
  content = "ManualRecordingBubbleI18n:content",
}

export enum AudioBarI18n {
  section = "AudioBarI18n:section",
  limitTip = "AudioBarI18n:limitTip",
  exceedlimitTip = "AudioBarI18n:exceedlimitTip",
}

export enum CreateDocFromLocalFileI18n {
  title = "CreateDocFromLocalFileI18n:title",
  uploadingTip = "CreateDocFromLocalFileI18n:uploadingTip",
  uploadingInfo = "CreateDocFromLocalFileI18n:uploadingInfo",
  errorTip = "CreateDocFromLocalFileI18n:errorTip",
  tryAgain = "CreateDocFromLocalFileI18n:tryAgain",
  back = "CreateDocFromLocalFileI18n:back",
}

export enum SubscribeButtonI18n {
  subscribe = "SubscribeButtonI18n:subscribe",
  unSubscribe = "SubscribeButtonI18n:unSubscribe",
  subscribeTip = "SubscribeButtonI18n:subscribeTip",
  unSubscribeTip = "SubscribeButtonI18n:unSubscribeTip",
}

export enum BundlesHubI18n {
  new = "BundlesHubI18n:new",
  title = "BundlesHubI18n:title",
  content = "BundlesHubI18n:content",
  create = "BundlesHubI18n:create",
  edit = "BundlesHubI18n:edit",
}

export enum BundlesEditorI18n {
  title = "BundlesEditorI18n:title",
  add = "BundlesEditorI18n:add",
  remove = "BundlesEditorI18n:remove",
  description = "BundlesEditorI18n:description",
  joinCollection = "BundlesEditorI18n:joinCollection",
  chooseCollection = "BundlesEditorI18n:chooseCollection",
  namePlaceholder = "BundlesEditorI18n:namePlaceholder",
  descPlaceholder = "BundlesEditorI18n:descPlaceholder",
  repick = "BundlesEditorI18n:repick",
  ok = "BundlesEditorI18n:ok",
  docPickerTitle = "BundlesEditorI18n:docPickerTitle",
  docPickerTips = "BundlesEditorI18n:docPickerTips",
  docPickerSubmit = "BundlesEditorI18n:docPickerSubmit",
  emptyTitle = "BundlesEditorI18n:emptyTitle",
  emptySubTitle = "BundlesEditorI18n:emptySubTitle",
}

export enum PlayNextTipI18n {
  next = "PlayNextTipI18n:next",
  nextPlay = "PlayNextTipI18n:nextPlay",
  playNow = "PlayNextTipI18n:playNow",
  noDoc = "PlayNextTipI18n:noDoc",
}

export enum ErrorPageI18n {
  message = "ErrorPageI18n:message",
}

export enum ErrorBoundaryI18n {
  errorTitle = "ErrorBoundaryI18n:errorTitle",
  errorSubtitle = "ErrorBoundaryI18n:errorSubtitle",
  errorContentCopy = "ErrorBoundaryI18n:errorContentCopy",
  errorBack = "ErrorBoundaryI18n:errorBack",
}

export enum AppNoticePageI18n {
  title = "AppNoticePageI18n:title",
  to = "AppNoticePageI18n:to",
  body = "AppNoticePageI18n:body",
  foot = "AppNoticePageI18n:foot",
  author = "AppNoticePageI18n:author",
  link = "AppNoticePageI18n:link",
  goToDownload = "AppNoticePageI18n:goToDownload",
}
