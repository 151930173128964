import {
  CommandBar,
  DirectionalHint,
  ICommandBarItemProps,
  makeStyles,
  MessageBar,
  MessageBarType,
  TeachingBubble,
  ZIndexes,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import MixedContent from "../../components/rc/MixedContent";
import { IconName } from "../../config/icons";
import { AdminUserGuideIds, generateCategoryHash, ResourceScope } from "../../config/rc";
import { PagePath } from "../../config/routes";
import { useRouteViewDocCallback } from "../../hooks/rc/useRoute";
import { useQueryWebPages } from "../../hooks/useQuery";
import { useRemoveFromCategory } from "../../hooks/useRemoveFromCategory";
import { rem } from "../../lib/unit";
import {
  ContentHubNS,
  ContinueEditDialog,
  EmptyContentI18n,
  NotificationTutor,
  View,
} from "../../locales";
import { navigateTo } from "../../services/history";
import { logger } from "../../services/logger";
import { requestWeb, WEB_API } from "../../services/request";
import { RCDocumentType } from "../../services/share";
import { delStorage, getStorage, setStorage } from "../../services/storage";
import CategoryPickerDialog from "../dialog/CategoryPickerDialog";
import CreateDocDialog from "../dialog/CreateDocDialog";
import { showDeleteDialog } from "../dialog/DeleteDialog";
import TingDocLoadingDialog, { TingDocLoadingDialogProps } from "../dialog/TingDocLoadingDialog";
import { UploadDocFileType } from "../dialog/UploadLocalFile";
import { pageContentContainer } from "../navigation/TopBar";

const useBarStyle = makeStyles(theme => ({
  root: {
    position: "fixed",
    right: "0",
    bottom: "30px",
    left: "0",
    width: "240px",
    margin: "0 auto",
    zIndex: ZIndexes.KeytipLayer,
    color: theme.palette.white,
    background: theme.palette.black,
    borderRadius: "5px",
  },
  icon: {
    color: theme.palette.white,
  },
  text: {
    borderRight: `1px solid ${theme.palette.white}`,
  },
  dismissal: {
    ".ms-Button-flexContainer .ms-Button-icon": {
      color: theme.palette.white,
    },
  },
}));

export default function ContentHub({ isAdmin = true }: { isAdmin?: boolean }) {
  const {
    values: documents,
    hasNextPage,
    loadMore,
    refetch,
    indicatorVisible,
  } = useQueryWebPages(
    isAdmin ? WEB_API.myTenantDocuments : WEB_API.myDocuments,
    {
      params: {
        $expand: ["category"],
        docTypes: [
          RCDocumentType.TingDoc,
          RCDocumentType.Video,
          RCDocumentType.ExcelFile,
          RCDocumentType.PDFFile,
          RCDocumentType.PPTFile,
          RCDocumentType.WordFile,
        ],
      },
    },
    { refetchOnMount: isAdmin }
  );

  const [
    reportDownloadBarShow,
    { setFalse: closeReportDownloadBar, setTrue: openReportDownloadBar },
  ] = useBoolean(false);

  const barStyle = useBarStyle();

  const inputRef = useRef<HTMLInputElement>();
  const triggerFilePicker = useCallback(() => (inputRef.current as any)?.click(), []);

  const viewDoc = useRouteViewDocCallback({ scope: ResourceScope.mytenant });
  const [hiddenNewModal, setHiddenNewModal] = useState(true);
  const hide = useCallback(() => {
    setHiddenNewModal(true);
    refetch();
  }, [refetch]);

  const [tingDocLoadingDialogProps, setTingDocLoadingDialogProps] =
    useState<TingDocLoadingDialogProps>({ hidden: true, docInfo: {} });

  const delDoc = useCallback(
    (doc: CDS.Document) =>
      requestWeb(isAdmin ? WEB_API.myTenantDocument : WEB_API.myDocument, {
        vars: { id: doc.id! },
        method: "DELETE",
      }).then(() => {
        refetch({ cancelRefetch: true });
      }),
    [isAdmin, refetch]
  );
  const patchDoc = useCallback(
    (meta: Partial<CDS.Document["metadata"]>, doc: CDS.Document) =>
      requestWeb(isAdmin ? WEB_API.myTenantDocumentMetadata : WEB_API.myDocumentMetadata, {
        vars: { id: doc.id! },
        data: meta,
        method: "PATCH",
      }).then(() => refetch()),
    [isAdmin, refetch]
  );

  const editDoc = useCallback(
    (doc: CDS.Document) => {
      navigateTo(PagePath.edit, { params: { id: doc.id }, state: { isAdmin } });
    },
    [isAdmin]
  );

  const [fileOrigin, setFileOrigin] = useState<"local" | "onedrive">();
  const [uploadType, setUploadType] = useState<UploadDocFileType>();
  const [uploadStart, setUploadStart] = useState<boolean>();
  const { t } = useTranslation();

  // 解决setUploadStart更新延时问题
  useEffect(() => {
    if (uploadStart) {
      setUploadStart(false);
      triggerFilePicker();
    }
  }, [triggerFilePicker, uploadStart]);

  const createTingDocBtnClick = useCallback(() => {
    logger?.telemetry("ClickCreateFromLocalBtn", { clickType: "FromCreate" });
    setUploadType("tingdoc");
    setFileOrigin("local");
    setHiddenNewModal(false);
    setUploadStart(true);
  }, []);

  const tingdocItems = useMemo<ICommandBarItemProps[]>(
    () => [
      {
        id: AdminUserGuideIds.createNew,
        // disabled: true,
        key: "new",
        text: t(ContentHubNS.create),
        cacheKey: "createNew", // changing this key will invalidate this item's cache
        iconProps: { iconName: IconName.Add },
        subMenuProps: {
          items: [
            ...(isAdmin
              ? [
                  {
                    key: "onedrive-tingdoc",
                    text: t(ContentHubNS.createFromOneDrive),
                    "data-automation-id": "newFromOnedrive", // optional
                    onClick: () => {
                      logger?.telemetry("ClickCreateFromOneDriveBtn", { clickType: "FromCreate" });
                      setFileOrigin("onedrive");
                      setUploadType("tingdoc");
                      setHiddenNewModal(false);
                    },
                  },
                ]
              : []),
            {
              key: "local-tingdoc",
              text: t(ContentHubNS.createFromLocal),
              "data-automation-id": "newFromLocal", // optional
              onClick: createTingDocBtnClick,
            },
          ],
        },
      },
      {
        id: AdminUserGuideIds.uploadFile,
        // disabled: true,
        key: "uploadFile",
        text: t(ContentHubNS.uploadFile),
        cacheKey: "uploadFile", // changing this key will invalidate this item's cache
        iconProps: { iconName: IconName.Upload },
        subMenuProps: {
          items: [
            ...(isAdmin
              ? [
                  {
                    key: "onedrive-video",
                    text: t(ContentHubNS.createVideoFromOneDrive),
                    "data-automation-id": "newFromOnedrive", // optional
                    onClick: () => {
                      logger?.telemetry("ClickCreateFromOneDriveBtn", {
                        clickType: "FromUploadFile",
                      });
                      setFileOrigin("onedrive");
                      setUploadType("video");
                      setHiddenNewModal(false);
                    },
                  },
                ]
              : []),
            {
              key: "local",
              text: t(ContentHubNS.createFromComputer),
              "data-automation-id": "newFromLocal", // optional
              onClick: () => {
                logger?.telemetry("ClickCreateFromLocalBtn", { clickType: "FromUploadFile" });
                setFileOrigin("local");
                setUploadType("office");
                setHiddenNewModal(false);
                setUploadStart(true);
              },
            },
          ],
        },
      },
    ],
    [createTingDocBtnClick, isAdmin, t]
  );

  const [tipsTargetId, setTipsTargetId] = useState<string>();
  const onDismissTeachingBubble = useCallback(() => setTipsTargetId(""), []);
  const [pickerDocuemnt, setPickerDocuemnt] = useState<CDS.Document>();
  const closeCategoryPicker = useCallback(() => setPickerDocuemnt(undefined), []);
  const onReadingDetailsDownload = useCallback(
    (id: string) => {
      openReportDownloadBar();
      requestWeb(WEB_API.readingDetails, {
        vars: { id },
        params: {
          reportType: ["UserRead"],
        },
        method: "GET",
      })
        .then(datas => {
          const link = document.createElement("a");
          link.href = datas.data.downloadUrl;
          document.body.appendChild(link);
          link.download = "";
          link.click();
          document.body.removeChild(link);
        })
        .finally(closeReportDownloadBar);
    },
    [closeReportDownloadBar, openReportDownloadBar]
  );

  const onCategorySubmit = useCallback(
    (categoryId: string) => {
      const docId = pickerDocuemnt?.id;
      if (docId && categoryId) {
        return requestWeb(WEB_API.myTenantCategoryDocuments, {
          vars: { categoryId },
          method: "POST",
          data: { documentIds: [docId] },
        })
          .then(() => {
            refetch();
            if (!getStorage("flag", "ContentHubShowPushNotifyTips")) {
              setTipsTargetId(categoryId);
              setStorage("flag", true, "ContentHubShowPushNotifyTips");
            }
            logger?.telemetry("AddDocToCategorySuccess", {
              DoctType: pickerDocuemnt?.metadata?.type!,
              DocId: docId!,
            });
          })
          .finally(() => setPickerDocuemnt(undefined));
      }
    },
    [pickerDocuemnt, refetch]
  );

  useEffect(() => {
    const storageSuffix = isAdmin ? "admin" : "me";
    let savedDoc = getStorage("editDocAutoSave", storageSuffix);

    // deal with autosave doc in previous version.
    if (!savedDoc) {
      savedDoc = getStorage("editDocAutoSave");
      if (savedDoc) {
        delStorage("editDocAutoSave");
        setStorage("editDocAutoSave", savedDoc, storageSuffix);
      }
    }

    if (savedDoc) {
      showDeleteDialog({
        title: t(ContinueEditDialog.title),
        subText: t(ContinueEditDialog.content),
        confirmButtonText: t(ContinueEditDialog.yes),
        cancelButtonText: t(ContinueEditDialog.no),
        doDelete: () => delStorage("editDocAutoSave", storageSuffix),
        onDismiss: () => {
          logger?.telemetry("ClickContinueEditBtn", { DocId: savedDoc?.id! });
          navigateTo(PagePath.edit, {
            state: { isAdmin },
          });
        },
        showCloseButton: false,
      });
      return () => showDeleteDialog(null);
    }
  }, [isAdmin, t]);

  return (
    <div className={pageContentContainer} style={{ height: "100%", overflow: "hidden" }}>
      <MixedContent
        bundles={[]}
        scope={ResourceScope.mytenant}
        hasNextPageDoc={hasNextPage}
        loadMoreDoc={loadMore}
        isFetchingDoc={indicatorVisible}
        documents={documents}
        tapDoc={viewDoc}
        delDoc={delDoc}
        onAddToCategory={isAdmin ? setPickerDocuemnt : undefined}
        onReadingDetailsDownload={isAdmin ? onReadingDetailsDownload : undefined}
        onRemoveDocFromCategory={useRemoveFromCategory(refetch)}
        patchDocMeta={patchDoc}
        editDoc={editDoc}
        emptyTitle={t(EmptyContentI18n.emptyTitle)}
        emptySubTitle={t(EmptyContentI18n.emptySubTitle, { context: "contentHub" })}
        emptyButtonProps={{
          onClick: createTingDocBtnClick,
          text: t(ContentHubNS.emptyCreate),
        }}
        hideLink={isAdmin}
        showCreator={isAdmin}
        showTime
        contentContainerStyle={{
          height: `calc(100% - ${rem(50)})`,
          overflow: "scroll",
        }}
      >
        <CommandBar items={tingdocItems} />
        {reportDownloadBarShow && (
          <MessageBar
            styles={barStyle}
            messageBarType={MessageBarType.info}
            dismissButtonAriaLabel="Close"
            onDismiss={closeReportDownloadBar}
          >
            {t(View.downloadReport_ing)}
          </MessageBar>
        )}
      </MixedContent>
      <CreateDocDialog
        isAdmin={isAdmin}
        fileOrigin={fileOrigin}
        localFileInputRef={inputRef}
        hidden={hiddenNewModal}
        uploadFileType={uploadType}
        onDismiss={hide}
        onFilePickerSubmit={docInfo => {
          setHiddenNewModal(true);
          setTingDocLoadingDialogProps({ docInfo, hidden: false });
        }}
      />
      <TingDocLoadingDialog
        {...tingDocLoadingDialogProps}
        isAdmin={isAdmin}
        onDismiss={() => {
          setTingDocLoadingDialogProps({ hidden: true, docInfo: {} });
        }}
      />
      <CategoryPickerDialog
        hidden={!pickerDocuemnt || !!pickerDocuemnt.extended?.categoryId}
        onSubmit={onCategorySubmit}
        onDismiss={closeCategoryPicker}
        onCreate={closeCategoryPicker}
      />
      {tipsTargetId && (
        <TeachingBubble
          onDismiss={onDismissTeachingBubble}
          target={`#treeNode_${tipsTargetId}`}
          headline={t(NotificationTutor.title)}
          hasCloseButton
          calloutProps={{
            directionalHint: DirectionalHint.topCenter,
            preventDismissOnLostFocus: true,
          }}
          secondaryButtonProps={{
            text: t(NotificationTutor.action),
            href: generateCategoryHash(tipsTargetId, ResourceScope.mytenant),
          }}
        >
          <Trans i18nKey={NotificationTutor.detail} components={[<strong />]} />
        </TeachingBubble>
      )}
    </div>
  );
}
