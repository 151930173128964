import { useCallback, useEffect, useMemo, useState } from "react";
import { Checkbox, List, mergeStyles } from "@fluentui/react";
import SelectableCard, { SelectableCardProps } from "../cards/SelectableCard";
import { SortableData } from "../cards/SortableCard";
import { rem } from "../../lib/unit";
import EmptyContent from "../empty/EmptyContent";
import { useTranslation } from "react-i18next";
import { ContentPickerI18n } from "../../locales";

type ItemType = SortableData & { itemStatus?: SelectableCardProps["status"] };

export default function DocumentPicker({
  dataList,
  selectedItems,
  onSelect,
  className,
  enableShimmer,
  maxItems,
  emptyMessage,
  showSelectAll = false,
}: {
  dataList?: SortableData[];
  selectedItems?: SortableData[];
  className?: string;
  onSelect?: (item: SortableData) => void;
  enableShimmer?: boolean;
  maxItems: number;
  emptyMessage?: string;
  showSelectAll?: boolean;
}) {
  const { t } = useTranslation();
  const items = useMemo<ItemType[]>(() => {
    const shimmerCount = 3;
    if (!dataList) {
      return Array(shimmerCount);
    }
    return dataList
      .map<ItemType>(data =>
        !selectedItems
          ? data
          : {
              ...data,
              itemStatus: selectedItems.some(i => i.id === data.id)
                ? "checked"
                : selectedItems.length >= maxItems
                ? "disabled"
                : "unchecked",
            }
      )
      .concat(enableShimmer ? Array(shimmerCount) : []);
  }, [dataList, enableShimmer, maxItems, selectedItems]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  useEffect(() => {
    if (dataList?.length === selectedItems?.length || selectedItems?.length === maxItems) {
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
    }
  }, [dataList, selectedItems, maxItems]);
  const renderCell = useCallback(
    (item?: ItemType) => {
      const onTap =
        onSelect &&
        item &&
        (() => {
          item.itemStatus !== "disabled" && onSelect(item);
          if (dataList?.length === selectedItems?.length || selectedItems?.length === maxItems) {
            setIsSelectedAll(true);
          }
        });
      return <SelectableCard data={item} onTap={onTap} status={item?.itemStatus} />;
    },
    [onSelect, maxItems, dataList, selectedItems]
  );
  const contentClassName = mergeStyles({ height: rem(380), overflow: "auto" }, className);

  const onClickSelecteAll = useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
      items.forEach(item => {
        if (item.itemStatus === "checked" && onSelect) {
          onSelect(item);
        }
      });

      if (!!checked) {
        const itemsSelected = items?.slice(0, maxItems);
        itemsSelected?.forEach(item => {
          if (onSelect) {
            onSelect(item);
          }
        });
      }
    },
    [items, maxItems, onSelect]
  );
  return !enableShimmer && items?.length === 0 ? (
    <EmptyContent className={contentClassName} message={emptyMessage} />
  ) : (
    <>
      {showSelectAll && (
        <Checkbox
          checked={isSelectedAll}
          label={t(ContentPickerI18n.selectAll)}
          styles={{ root: { padding: rem(12) } }}
          onChange={onClickSelecteAll}
        />
      )}
      <List
        data-is-scrollable
        className={contentClassName}
        items={items}
        onRenderCell={renderCell}
      />
    </>
  );
}
