import { DefaultButton, Icon, IconButton } from "@fluentui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { ResourceScope } from "../../config/rc";
import { View } from "../../locales";
import { logger } from "../../services/logger";
import { EntityType, RCDocumentType, ShareInfo } from "../../services/share";
import CopyCallout from "../common/CopyCallout";
import { VideoDoc } from "../../models/VideoDoc";
import { isIOS } from "../../config/browser";
import { useFileToken } from "../../hooks/useFileToken";

import "./VideoPlayer.scss";
import PlayerHeader from "../navigation/PlayerHeader";
const SHOW_NEXTTIP_SECOND = 10;

export interface VideoPlayerProps {
  scope: ResourceScope;
  doc: VideoDoc;
  category?: { id: string; name: string };
  hideTeamsShare?: boolean;
  rcShareInfo?: ShareInfo;
  onCloseToEnd?: (isCloseToEnd: boolean, noVoice?: boolean) => void;
  onEnd?: () => void;
  onTapBack?: () => void;
}

function useVideoLogger() {
  return useMemo(() => {
    const start = performance.now();
    return {
      onLoadedData: () => logger?.time("video:loadedData", performance.now() - start),
      onError: (err: any) => logger?.error(err, "video-error"),
    };
  }, []);
}

export default function VideoPlayer({
  doc,
  category,
  scope,
  hideTeamsShare,
  rcShareInfo,
  onCloseToEnd,
  onEnd,
  onTapBack,
}: VideoPlayerProps): React.ReactElement {
  const { t } = useTranslation();
  const isPublic = scope === ResourceScope.feeds;
  const { token } = useFileToken();
  const [shareInfo, setShareInfo] = useState<ShareInfo>();
  const [isCloseToEnd, setIsCloseToEnd] = useState(false);
  const closeLinkCallout = useCallback(() => setShareInfo(undefined), []);
  const onLinkClick = useCallback(() => {
    logger?.telemetry("CopyDeepLink", {
      WorkType: EntityType.Document,
      DocumentType: RCDocumentType.Video,
      WorkId: doc.id!,
      Name: scope === ResourceScope.feeds ? doc.metadata.name : undefined!,
      Scope: scope,
      CategoryId: doc.extended?.categoryId!,
      Target: "VideoPlayer",
    });

    setShareInfo(rcShareInfo);
  }, [doc, rcShareInfo, scope]);
  const videoLogger = useVideoLogger();
  const onDocDownload = useCallback(() => {
    logger?.telemetry("VideoDownload", {
      DocumentId: doc.id!,
      DocumentName: doc.metadata?.name!,
      Scope: scope,
      Type: doc.metadata.type!,
    });

    if (doc.downloadUrl) {
      fetch(doc.downloadUrl)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = doc.metadata.name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(error => console.error("Error:", error));
    }
  }, [scope, doc]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  return (
    <>
      <PlayerHeader
        title={doc.metadata.name}
        category={category}
        type={"video"}
        onTapBack={onTapBack}
      >
        <div className="ViewTop-left">
          <p className="Video-error">{errorMsg}</p>
        </div>
        <div className="ViewTop-right">
          {doc.downloadUrl && (
            <IconButton
              hidden={hideTeamsShare}
              className="ViewTop-download"
              // href={`${doc.downloadUrl}`}
              title={t(View.download)}
              download={doc.metadata?.name}
              onClick={onDocDownload}
              iconProps={{ iconName: IconName.Download }}
            />
          )}
          <Icon
            hidden={hideTeamsShare}
            iconName={IconName.Link}
            onClick={onLinkClick}
            className="ViewTop-link"
            title={t(View.copyLink)}
          />
          <DefaultButton className="ViewTop-backButton" onClick={onTapBack} text={t(View.close)} />
        </div>
      </PlayerHeader>
      <div className="Video-container">
        <video
          src={isPublic ? doc.video : token ? `${doc.video}?${token}` : undefined}
          className="Video"
          poster={doc.metadata.cover}
          controls={isIOS ? false : true}
          disablePictureInPicture
          controlsList="nodownload"
          autoPlay
          onLoadedData={videoLogger.onLoadedData}
          onError={obj => {
            const mErr = (obj?.target as HTMLVideoElement)?.error as MediaError;
            if (!isPublic && mErr?.code && mErr?.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
              setErrorMsg(t(View.errContent));
            }
            videoLogger.onError(obj);
          }}
          onClick={isIOS ? ele => (ele.currentTarget as HTMLVideoElement)?.play() : undefined}
          onTimeUpdate={ev => {
            const remainingTime = ev.currentTarget.duration - ev.currentTarget.currentTime;
            if (remainingTime <= SHOW_NEXTTIP_SECOND && !isCloseToEnd) {
              onCloseToEnd && onCloseToEnd(true, ev.currentTarget.duration === 0);
              setIsCloseToEnd(true);
            } else if (remainingTime > SHOW_NEXTTIP_SECOND && isCloseToEnd) {
              onCloseToEnd && onCloseToEnd(false);
              setIsCloseToEnd(false);
            }
          }}
          onEnded={onEnd}
        />
        {isIOS && <Icon iconName={IconName.MSNVideosSolid} className="Video-icon" />}
      </div>
      <CopyCallout item={shareInfo} target=".ViewTop-link" onDismiss={closeLinkCallout} />
    </>
  );
}
