/* eslint-disable @dragongate/no-magic-numbers */
import { useBoolean } from "@fluentui/react-hooks";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResourceScope } from "../../config/rc";
import { useRouteViewDocCallback } from "../../hooks/rc/useRoute";
import { useQueryWeb, useQueryWebPages } from "../../hooks/useQuery";
import { rem } from "../../lib/unit";
import { EmptyContentI18n } from "../../locales";
import { logger } from "../../services/logger";
import { requestWeb, WEB_API } from "../../services/request";
import { RCDocumentType } from "../../services/share";
import CreateTemplateDialog from "../dialog/CreateTemplateDialog";
import TemplateCompressionDialog from "../dialog/TemplateCompressionDialog";
import TemplateNotificationDialog from "../dialog/TemplateNotificationDialog";
import TemplateHubBar from "../navigation/TemplateHubBar";
import { pageContentContainer } from "../navigation/TopBar";
import MixedContent from "./MixedContent";
// import TemplateCompressionDialog from "../dialog/TemplateCompressionDialog";

export interface TemplateHubProps {
  isAdmin?: boolean;
}

export default function TemplateHub({ isAdmin }: TemplateHubProps) {
  const {
    values: documents,
    hasNextPage,
    indicatorVisible,
    loadMore,
    refetch,
  } = useQueryWebPages(isAdmin ? WEB_API.myTenantDocuments : WEB_API.myTenantTemplateDocuments, {
    params: isAdmin
      ? {
          docTypes: [
            RCDocumentType.PPTTemplate,
            RCDocumentType.WordTemplate,
            RCDocumentType.ExcelTemplate,
          ],
        }
      : undefined,
  });

  const viewDoc = useRouteViewDocCallback({ scope: ResourceScope.mytenant });

  const delDoc = useCallback(
    (doc: CDS.Document) =>
      requestWeb(WEB_API.myTenantDocument, { vars: { id: doc.id! }, method: "DELETE" }).then(() => {
        refetch({ cancelRefetch: true });
      }),
    [refetch]
  );

  const { t } = useTranslation();

  const [
    isNotificationEditorVisible,
    { setFalse: closeNotificationEditor, setTrue: openNotificationEditor },
  ] = useBoolean(false);

  const [isCreateDialogVisible, { setFalse: closeCreateDialog, setTrue: openCreateDialog }] =
    useBoolean(false);

  const [isNotificationBubbleVisible, { setTrue: showNotificationBubbleVisible }] =
    useBoolean(false);

  const hide = useCallback(() => {
    closeCreateDialog();
    refetch();
  }, [closeCreateDialog, refetch]);
  const [zipDialogVisible, { setTrue: showZipDialog, setFalse: hideZipDialog }] = useBoolean(false);
  const [enableDownload, setEnableDownload] = useState(false);
  const { data: zipResult } = useQueryWeb(WEB_API.myTenantTemplateZipUrl, {
    config: {
      enabled: !!isAdmin,
      refetchInterval: 10000,
      onSuccess: async data => {
        if (data.zipUrl) {
          setEnableDownload(true);
        } else {
          setEnableDownload(false);
        }
      },
      onError: err => {
        console.log(err.message);
        setEnableDownload(false);
      },
    },
  });

  const clickCompressBtn = useCallback(() => {
    showZipDialog();
    logger?.telemetry("CompressTemplate", { Target: "template" });
  }, [showZipDialog]);
  const clickDownloadBtn = useCallback(() => {
    logger?.telemetry("DownloadTemplate", {
      Target: "template",
    });

    if (zipResult && zipResult.zipUrl) {
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = zipResult.zipUrl;
      a.download = "templates.zip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [zipResult]);

  return (
    <div className={pageContentContainer} style={{ height: "100%", overflow: "hidden" }}>
      <MixedContent
        bundles={[]}
        scope={ResourceScope.mytenant}
        hasNextPageDoc={hasNextPage}
        loadMoreDoc={loadMore}
        isFetchingDoc={indicatorVisible}
        documents={documents}
        tapDoc={viewDoc}
        delDoc={isAdmin ? delDoc : undefined}
        emptyTitle={t(EmptyContentI18n.emptyTitle)}
        emptySubTitle={isAdmin ? t(EmptyContentI18n.emptySubTitle, { context: "templateHub" }) : ""}
        showCreator={isAdmin}
        contentContainerStyle={{
          height: `calc(100% - ${isAdmin ? rem(50) : "0px"})`,
          overflow: "scroll",
        }}
      >
        {isAdmin && (
          <TemplateHubBar
            onClickNotification={() => {
              logger?.telemetry("ClickNotificationBtn", {
                Target: "template",
              });
              openNotificationEditor();
            }}
            onClickUpload={openCreateDialog}
            showNotificationBubble={isNotificationBubbleVisible}
            onClickCompressResource={clickCompressBtn}
            onClickDownloadResource={clickDownloadBtn}
            enableDownload={enableDownload}
          ></TemplateHubBar>
        )}
      </MixedContent>
      <CreateTemplateDialog
        hidden={!isCreateDialogVisible}
        onDismiss={hide}
        onTemplateCreated={showNotificationBubbleVisible}
      />
      <TemplateNotificationDialog
        onDismiss={closeNotificationEditor}
        hidden={!isNotificationEditorVisible}
      />
      <TemplateCompressionDialog onDismiss={hideZipDialog} hidden={!zipDialogVisible} />
    </div>
  );
}
