import { CommandBar, ICommandBarItemProps, TeachingBubble } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { CategoryActionI18n, ContentHubNS } from "../../locales";
import { logger } from "../../services/logger";

export interface TemplateHubBarProps {
  onClickNotification?: () => void;
  onClickUpload?: () => void;
  showNotificationBubble?: boolean;
  onClickCompressResource?: () => void;
  onClickDownloadResource?: () => void;
  enableDownload?: boolean;
}
const NotificationButtonId = "TemplateHubBar-Notification";
const CreateTemplateButtonId = "TemplateHubBar-Creation";
const DownloadResourceId = "TemplateHubBar-DownloadResource";
export default function TemplateHubBar({
  onClickNotification,
  onClickUpload,
  showNotificationBubble,
  onClickCompressResource,
  onClickDownloadResource,
  enableDownload,
}: TemplateHubBarProps) {
  const { t } = useTranslation();
  const [compressResourceFlag, { setTrue: compressResourceHidden }] = useBoolean(false);
  const items = useMemo<ICommandBarItemProps[]>(
    () => [
      {
        id: CreateTemplateButtonId,
        key: "upload",
        text: t(ContentHubNS.uploadTemplate),
        cacheKey: "createNew", // changing this key will invalidate this item's cache
        iconProps: { iconName: IconName.Upload },
        onClick: () => {
          logger?.telemetry("ClickCreateTemplateBtn");
          onClickUpload?.();
        },
      },
      {
        id: NotificationButtonId,
        key: "Notification",
        text: t(CategoryActionI18n.notify),
        cacheKey: "Notification", // changing this key will invalidate this item's cache
        iconProps: { iconName: IconName.Ringer },
        onClick: () => {
          onClickNotification?.();
        },
      },
      {
        id: DownloadResourceId,
        key: "CompressResource",
        text: t(CategoryActionI18n.compressResource),
        iconProps: { iconName: IconName.Archive },
        onClick: () => {
          onClickCompressResource?.();
        },
      },

      {
        key: "DownloadResource",
        text: t(CategoryActionI18n.downloadResource),
        disabled: !enableDownload,
        iconProps: { iconName: IconName.Download },
        onClick: () => {
          onClickDownloadResource?.();
        },
      },
    ],
    [
      t,
      enableDownload,
      onClickUpload,
      onClickNotification,
      onClickCompressResource,
      onClickDownloadResource,
    ]
  );
  return (
    <>
      <CommandBar items={items} />
      {compressResourceFlag ? null : (
        <TeachingBubble target={`#${DownloadResourceId}`} onDismiss={compressResourceHidden}>
          {t(CategoryActionI18n.compressResourceBubble)}
        </TeachingBubble>
      )}
    </>
  );
}
