import { CommandBar, ICommandBarItemProps, TeachingBubble } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { CategoryActionI18n } from "../../locales";

export interface CategoryContentBarProps {
  enableDownload?: boolean;
  compressButtonText?: string;
  onClickNotification?: () => void;
  onClickCompressResource?: () => void;
  onClickDownloadResource?: () => void;
}
const NotificationButtonId = "CategoryContentBar-Notification";
const DownloadResourceId = "CategoryContentBar-DownloadResource";
export default function CategoryContentBar({
  enableDownload,
  compressButtonText,
  onClickNotification,
  onClickCompressResource,
  onClickDownloadResource,
}: CategoryContentBarProps) {
  const { t } = useTranslation();
  const [compressResourceFlag, { setTrue: compressResourceHidden }] = useBoolean(false);
  const items = useMemo<ICommandBarItemProps[]>(
    () => [
      {
        id: NotificationButtonId,
        key: "Notification",
        text: t(CategoryActionI18n.notify),
        cacheKey: "Notification", // changing this key will invalidate this item's cache
        iconProps: { iconName: IconName.Ringer },
        onClick: () => {
          onClickNotification?.();
        },
      },
      {
        id: DownloadResourceId,
        key: "CompressResource",
        disabled: compressButtonText === t(CategoryActionI18n.compressingResource),
        text: compressButtonText,
        iconProps: { iconName: IconName.Archive },
        onClick: () => {
          onClickCompressResource?.();
        },
      },
      ...(enableDownload
        ? [
            {
              key: "DownloadResource",
              text: t(CategoryActionI18n.downloadResource),
              iconProps: { iconName: IconName.Download },
              onClick: () => {
                onClickDownloadResource?.();
              },
            },
          ]
        : []),
    ],
    [
      t,
      enableDownload,
      compressButtonText,
      onClickNotification,
      onClickCompressResource,
      onClickDownloadResource,
    ]
  );
  return (
    <>
      <CommandBar items={items} />
      {compressResourceFlag ? null : (
        <TeachingBubble target={`#${DownloadResourceId}`} onDismiss={compressResourceHidden}>
          {t(CategoryActionI18n.compressResourceBubble)}
        </TeachingBubble>
      )}
    </>
  );
}
