import { BaseDocumentModel } from "./BaseDocumentModel";

/**
 * 听听文档每页基本数据
 */
interface BaseTingPage extends Pick<CDS.Page, "id"> {
  /**
   * 标题
   */
  title?: string;

  /**
   * 总时长
   */
  duration: number;
}

/**
 * 编辑状态每页数据
 */
// export interface TingPageEditing extends BaseTingPage {
//   id: string;
//   /**
//    * 页面背景图片
//    */
//   img: CDS.Medium;
//   /**
//    * 音频
//    */
//   audios: CDS.Medium[];
//   /**
//    * 笔记
//    */
//   note?: string;
// }

/**
 * 字幕
 */
export interface Caption {
  id?: string;

  /**
   * 字幕内容
   */
  data: string;
  /**
   * 字幕总时长 ms
   */
  duration: number;
  /**
   * 字幕播放开始时间 ms
   */
  offset: number;
}
/**
 * 音频结构
 */
export interface Audio {
  id: string;
  /**
   * 音频URL
   */
  data: string;
  /**
   * 本地地址
   */
  _localPath?: string;
  /**
   * 音频时长
   */
  duration: number;
  /**
   * 字幕
   */
  captions?: Caption[];
}

/**
 * 视频结构
 */
export interface Video {
  id: string;
  /**
   * 视频URL
   */
  data: string;
}

/**
 * 腾讯视频结构
 */
export interface TxVideo {
  id: string;
  /**
   * 腾讯视频id
   */
  data: string;
}

/**
 * 播放状态听听文档每页数据内容
 */
export interface TingPage extends BaseTingPage {
  /**
   * 图片URL
   */
  img?: string;
  /**
   * 本地图片
   */
  _localImg?: string;

  /**
   * 音频
   */
  audios: Audio[];

  note?: CDS.Medium;
  /**
   * 视频
   */
  video?: Video;

  /**
   * 腾讯视频
   */
  txvideo?: TxVideo;
}

interface MediaMap {
  [index: string]: CDS.Medium;
}

/**
 * 过滤 undefined
 * @param x
 */
function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

/**
 * 根据IDlist提取ID列表
 * 会自动过滤掉 格式错误的的字幕资源
 * @param idList
 * @param mediaMap
 */
function parseCaptionByIdList(idList: string[], mediaMap: MediaMap): Caption[] {
  return idList
    .map<Caption | undefined>(id => {
      const m = mediaMap[id];
      if (m && m.type === "text" && m.data && m.meta && m.meta.duration) {
        return {
          id: m.id,
          data: m.data,
          duration: m.meta.duration || 0,
          offset: m.meta.offset || 0,
        };
      }
      // logger.error(`invalid caption ${id}`, m);
      return undefined;
    })
    .filter(notUndefined);
}

/**
 * 从cdsDocument的每一页的elements中解析出音频
 * 会自动过滤掉 格式错误的的音频资源
 * @param elements
 * @param mediaMap
 */
function parseAudios(elements: CDS.Element[], mediaMap: MediaMap): Audio[] {
  return elements
    .map(e => {
      if (!e.media_id || (e.is && e.is !== "audio")) {
        return undefined;
      }
      const elementMedia = mediaMap[e.media_id];
      if (!elementMedia || elementMedia.type !== "audio") {
        return undefined;
      }
      return {
        id: e.media_id,
        data: (elementMedia.urls && elementMedia.urls[0]) || elementMedia.data,
        _localPath: elementMedia._localPath,
        duration: (elementMedia.meta && elementMedia.meta.duration) || 0,
        captions: e.captions ? parseCaptionByIdList(e.captions, mediaMap) : [],
      };
    })
    .filter(notUndefined);
}

/**
 * TingDoc的实际类型
 */
// eslint-disable-next-line no-redeclare
export class TingDoc extends BaseDocumentModel {
  public readonly pages: TingPage[];

  public readonly settings: CDS.Settings;

  public readonly video?: string;

  public readonly downloadUrl?: string;
  /**
   * 解析cdsDocument到tingDoc
   * @param cdsDocument
   */
  public constructor(cdsDocument: CDS.Document, skipValidation?: boolean) {
    if (!skipValidation && !TingDoc.isTingDoc(cdsDocument)) {
      throw new Error(`invalid TingDoc type ${cdsDocument.metadata?.type} ${cdsDocument.id}`);
    }
    super(cdsDocument);
    const content = cdsDocument.content as CDS.TingDocumentContent;
    this.downloadUrl = cdsDocument.downloadUrl;
    let videoSrc: string | undefined;

    this.pages =
      content?.presentation?.pages.map(item => {
        const imgId = item.background_img_id;
        const elements = item.elements;
        const audios = elements ? parseAudios(elements, this.mediaMap) : [];
        const page: TingPage = {
          id: item.id,
          duration: audios.reduce((pre, a) => a.duration + pre, 0),
          img: imgId ? this.getMediaUrl(imgId) : "",
          audios,
        };
        if (elements && elements.length > 0) {
          elements.forEach(ele => {
            const media = this.mediaMap[ele.media_id!];
            if (media.type === "txvideo") {
              page.txvideo = media;
            } else if (media.type === "video") {
              videoSrc = this.getMediaUrl(media.id);
              page.video = {
                id: media.id,
                data: videoSrc,
              };
            } else {
              if (media.type !== "audio") {
                console.log("unkown type media", media);
              }
            }
          });
        }
        return page;
      }) || [];

    // only one video and no audio tingdoc play with videoplay component
    this.video =
      this.pages.length === 1 && this.pages[0].audios.length === 0 ? videoSrc : undefined;

    const settings = content?.presentation?.settings || {};
    this.settings = settings;
    if (settings && !settings.bgm_url && settings.background_music_id) {
      settings.bgm_url = this.getMediaUrl(settings.background_music_id as string);
    }
  }

  public static isTingDoc(doc: CDS.Document) {
    return doc.metadata?.type?.includes("tingdoc");
  }

  public static isVideoTingDoc(doc: CDS.Document): boolean {
    if (!TingDoc.isTingDoc(doc)) {
      return false;
    }
    const tingDoc = new TingDoc(doc);
    return !!tingDoc.video;
  }
}
