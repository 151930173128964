import { Icon, makeStyles, mergeStyles, MessageBar, PrimaryButton } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { RETIRE_DOC_URL } from "../../config/constant";
import { IconName } from "../../config/icons";
import { PagePath } from "../../config/routes";
import { useRouteNavCallbck } from "../../hooks/rc/useRoute";
import { rem } from "../../lib/unit";
import { AppDeployment, AppNoticePageI18n } from "../../locales";
import { logger } from "../../services/logger";
import { FontSizes } from "../../themes/fonts";

const useStyles = makeStyles({
  messageBarBtn: { fontSize: FontSizes.small, height: rem(24), marginLeft: rem(12) },
});

const useMessageBarStyles = makeStyles(theme => ({
  content: {
    backgroundColor: theme.palette.themeLighter,
    color: theme.palette.themePrimary,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    justifyContent: "center",
  },
  innerText: {
    "span >a": {
      padding: 0,
    },
    "span >i": {
      display: "inline",
      span: {
        padding: "0 2px 2px 0",
      },
    },
  },
  iconContainer: { display: "none" },
}));

export default function AdminMessageBar({
  height,
  isAdmin,
}: {
  height: string;
  isAdmin?: boolean;
}) {
  const { t } = useTranslation();
  const onDownloadClick = useRouteNavCallbck(PagePath.admin);

  const messageBarStyles = useMessageBarStyles();
  const styles = useStyles();
  return (
    <>
      <MessageBar className={mergeStyles({ height })} isMultiline={false} styles={messageBarStyles}>
        <Icon iconName={IconName.Warning} />
        {t(AppDeployment.messageBarContent)}
        <PrimaryButton
          className={styles.messageBarBtn}
          text={t(AppDeployment.messageBarBtn)}
          href={RETIRE_DOC_URL}
          target={"_blank"}
          onClick={() => logger?.telemetry("ClickAppDeploymentDocBtn")}
        />
        {isAdmin && (
          <PrimaryButton
            className={styles.messageBarBtn}
            text={t(AppNoticePageI18n.goToDownload)}
            target={"_blank"}
            onClick={onDownloadClick}
          />
        )}
      </MessageBar>
    </>
  );
}
